import { createReducer } from '@reduxjs/toolkit';
import {
  fetchCompanyPosts,
  fetchPosts,
  fetchUpdatedPosts,
  ignorePost,
  newPost,
  pinPost,
  postDelete,
  postDislike,
  postLike,
  postRepost,
  triggerFetchPosts,
  unpinPost,
  updatePost,
  listMyEvents,
  getMyEventDetails,
  getEditEventDetails,
  clearEditEvent,
  listUpcomingEvents,
  listPastEvents,
  listEventByMe, openCreatePostModal
} from '../actions/post.actions';

const initialState = {
  data: [],
  loading: false,
  flag: false,
  updatedPosts: [],
  deletedPosts: [],
  postSubmitted:false,
  eventsList:[],
  eventDetails:null,
  editEventDetails:null,
  upcomingEventsList:[],
  pastEventsList:[],
  myEventsList:[], 
  createPostModalOpen:false,
};

export const postReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPosts.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchPosts.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(fetchPosts.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(fetchCompanyPosts.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchCompanyPosts.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(fetchCompanyPosts.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(fetchUpdatedPosts.fulfilled, (state, action) => {
      state.data = action.payload;
    })

    .addCase(updatePost.pending, (state, action) => {
      state.flag = true;
    })
    .addCase(updatePost.fulfilled, (state, action) => {
      state.flag = false;
    })
    .addCase(updatePost.rejected, (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(ignorePost.pending, (state, action) => {
      state.flag = true;
    })
    .addCase(ignorePost.fulfilled, (state, action) => {
      state.flag = false;
    })
    .addCase(ignorePost.rejected, (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(postLike.pending, (state, action) => {
      state.flag = true;
    })
    .addCase(postLike.fulfilled, (state, action) => {
      state.flag = false;
    })
    .addCase(postLike.rejected, (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(postDelete.pending, (state, action) => {
      state.flag = true;
    })
    .addCase(postDelete.fulfilled, (state, action) => {
      state.flag = false;
      const { id } = action?.payload ?? {};
      if (id) {
        state.deletedPosts.push(id);
      }
    })
    .addCase(postDelete.rejected, (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(pinPost.pending, (state, action) => {
      state.flag = true;
    })
    .addCase(pinPost.fulfilled, (state, action) => {
      state.flag = true;
    })
    .addCase(pinPost.rejected, (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(unpinPost.pending, (state, action) => {
      state.flag = true;
    })
    .addCase(unpinPost.fulfilled, (state, action) => {
      state.flag = true;
    })
    .addCase(unpinPost.rejected, (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(postDislike.pending, (state, action) => {
      state.flag = true;
    })
    .addCase(postDislike.fulfilled, (state, action) => {
      state.flag = false;
    })
    .addCase(postDislike.rejected, (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(postRepost.pending, (state, action) => {
      state.flag = true;
    })
    .addCase(postRepost.fulfilled, (state, action) => {
      state.flag = false;
    })
    .addCase(postRepost.rejected, (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(newPost.pending, (state, action) => {
      state.flag = true;
    })
    .addCase(newPost.fulfilled, (state, action) => {
      state.flag = false;
      state.postSubmitted = true;
    })
    .addCase(newPost.rejected, (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(listMyEvents.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(listMyEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.eventsList = action.payload;
    })
    .addCase(listMyEvents.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(listUpcomingEvents.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(listUpcomingEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.upcomingEventsList = action.payload;
    })
    .addCase(listUpcomingEvents.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(listPastEvents.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(listPastEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.pastEventsList = action.payload;
    })
    .addCase(listPastEvents.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(listEventByMe.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(listEventByMe.fulfilled, (state, action) => {
      state.loading = false;
      state.myEventsList = action.payload;
    })
    .addCase(listEventByMe.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getMyEventDetails.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getMyEventDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.eventDetails = action.payload;
    })
    .addCase(getMyEventDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getEditEventDetails.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getEditEventDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.editEventDetails = action.payload;
    })
    .addCase(getEditEventDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(triggerFetchPosts, (state, action) => {
      state.refetchPosts = action.payload;
    })
    .addCase(clearEditEvent, (state, action) => {
      state.editEventDetails = null;
    })
    .addCase(openCreatePostModal, (state, action) => {
      state.createPostModalOpen = action.payload;
    });
});
