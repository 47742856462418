/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @nx/enforce-module-boundaries */
import { Button } from '@chakra-ui/react';
import { HomeIcon, JobsIcon, TeamIcon, TeamNewIcon } from '@mybridge/icons';
import { useDisclosure } from '@mybridge/ui/hooks';
import { IconButton } from '@mybridge/ui/icon-button';
import { Box, Circle, HStack } from '@mybridge/ui/layout';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConversationList from 'v4/components/header/conversation-list';
import StatusDropdown from 'v4/components/header/status-dropdown';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { UserOnboardingModal } from 'v4/snippets/onboarding/onboarding-modal';
import {
  setLoggedIn,
  setOpenAuthModal,
  setChatLoggedIn,
  setPageLoaded,
  logout,
} from 'v4/store/actions/auth.actions';
import { getOnboardingStatus } from 'v4/store/actions/onboarding.actions';
import { getUserProfileInfo } from 'v4/store/actions/user-profile.actions';
import { ActivateAccountModal } from '../activate-account-modal';
import { ChangeEmailModal } from '../change-email-modal';
import { ConfirmResetPasswordModal } from '../confirm-reset-password-modal';
import { RequestResetPasswordModal } from '../request-reset-password-modal';
import { SendVerifyLinkModal } from '../send-verify-link-modal';
import { SigninModal } from '../sign-in-modal';
import { SignupModal } from '../sign-up-modal';
import { ProfileMenu } from './profile-menu';
import NotificationDropdown from './notifications';
import { getAllConnectionsData } from 'v4/store/actions/connection.actions';
import MeetingDropdown from 'v4/components/header/meeting-dropdown';
import {
  setFcmToken,
  getNotificationList,
} from 'v4/store/actions/notification.actions';
import { getMessaging, onMessage } from 'firebase/messaging';
import { getFirebaseApp } from 'v4/utils/firebase';
import useFcmToken from 'v4/lib/useFcmToken';
import { setMeetingWindowed } from 'v4/store/actions/meeting.actions';
import MyBridgeAvatar from 'v4/components/common/MyBridgeAvatar';
import { PageRoutes } from 'v4/lib/routes';
import {
  setMobileDevice,
  setTabletDevice,
} from 'v4/store/actions/global.data.actions';
import { isBrowser, isMobile, isTablet } from 'react-device-detect';
import { SearchIcon } from '@mybridge/icons/Search';
import { Badge, Tooltip } from '@mybridge/ui';
import { AddIcon } from '@chakra-ui/icons';
import { openCreatePostModal } from 'v4/store/actions/post.actions';

export const AuthHeader = (props) => {
  const { loggedIn, openAuthModal } = useSelector((state) => state.user);
  const { currentMeetingState } = useSelector((state) => state.meeting);
  const { teamChatNotications } = useSelector((state) => state.chatReducer);
  const { userProfileInfo, loading } = useSelector(
    (state) => state.userProfile
  );
  const openAuthModalDisc = useDisclosure();
  const onboardingDisc = useDisclosure();
  const dispatch = useDispatch();
  const {
    push,
    pathname,
    query: { pg },
  } = useRouter();
  const { is_onboarding_completed, is_active = true } = userProfileInfo ?? {};
  const { onboardingStatus } = useSelector((state) => state.onboarding) ?? {};
  const { fcmToken, notificationPermissionStatus } = useFcmToken();
  const dbName = 'notificationsDB';
  const storeName = 'notifications';

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    console.log('FCM token:', fcmToken);
    if (fcmToken && userProfileInfo.fcm_token !== fcmToken) {
      // if (fcmToken) {
      dispatch(setFcmToken({ fcm_token: fcmToken }));
    }
  }, [fcmToken, notificationPermissionStatus]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const messaging = getMessaging(getFirebaseApp());
      const unsubscribe = onMessage(messaging, (payload) => {
        console.log('Foreground push notification received:', payload.data);
        dispatch(getNotificationList());
      });
      return () => {
        unsubscribe(); // Unsubscribe from the onMessage event
      };
    }
  }, []);

  useEffect(() => {
    const { data } = onboardingStatus ?? {};
    const { percentage } = data ?? {};
    if (percentage < 100) {
      const { profile, employment, photo, job_availabilty, connect } =
        data ?? {};
      const entries = [
        { name: 'profile', value: profile },
        { name: 'employment', value: employment },
        { name: 'photo', value: photo },
        { name: 'job_availabilty', value: job_availabilty },
        // { name: 'contact', value: contact },
        // { name: 'connect', value: connect },
      ];
      const notBoarded = entries?.find?.((e) => e.value === false)?.name
        ?.length;
      if (notBoarded) {
        // onboardingDisc?.onOpen?.();
        push('/onboarding');
      }
    }
  }, [onboardingStatus]);

  useEffect(() => {
    if (loggedIn) {
      if (userProfileInfo && !is_active) {
        setTimeout(() => {
          dispatch(setOpenAuthModal('activate-account'));
        }, 5000);
      } else {
        if (userProfileInfo?.email?.length) {
          dispatch(getOnboardingStatus({ emailId: userProfileInfo?.email }));
        }
      }
    }
  }, [userProfileInfo, pathname, is_active, loggedIn]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const chatAccess = localStorage.getItem('chatAccess');
      dispatch(getUserProfileInfo())
        .then((resp) => {
          const status_code = resp?.payload?.status_code ?? {};
          // console.log('status_code', status_code > 400);
          if (status_code > 400) {
            localStorage?.clear();
            dispatch(setLoggedIn(false));
            console.log('status_code true', status_code > 400);
          } else {
            dispatch(setLoggedIn(true));
            dispatch(getAllConnectionsData());
            if (chatAccess?.length) dispatch(setChatLoggedIn(true));
            console.log('status_code false', status_code > 400);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      dispatch(setPageLoaded(true));
    }
  }, [loading]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getUserProfileInfo());
    }
  }, [loggedIn]);

  const tid = useRef(-1);
  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      console.warn('Logged In', loggedIn, loading, pathname);
      if (loading) return;
      if (
        loggedIn ||
        PageRoutes?.find?.((r) => r.isPublic && pathname.match(r.path)?.[0])
      ) {
        console.warn('yes');
        return;
      }
      return push('/');
    }, 300);
  }, [loggedIn, loading, pathname]);

  useEffect(() => {
    if (openAuthModal && openAuthModal?.length) {
      openAuthModalDisc.onOpen();
    } else {
      openAuthModalDisc.onClose();
    }
  }, [openAuthModal]);

  const handleSignInOpen = () => {
    push('/signin');
    //dispatch(setOpenAuthModal('sign-in'));
  };

  const handleSignUpOpen = () => {
    push('/signup');
    //dispatch(setOpenAuthModal('sign-up'));
  };

  const onClose = () => {
    dispatch(setOpenAuthModal(''));
  };

  const userFullName = getUserFullName(userProfileInfo);
  const userProfilePic = getUserProfilePic(userProfileInfo);

  // console.log('teamChatNotications', teamChatNotications);

  return (
    <>
      {loggedIn ? (
        <>
          {isBrowser && (
            <HStack spacing="10px" justifyContent="space-around">
              <Tooltip label="Home" bg="black" p={2}>
                <IconButton
                  display={['none', 'none', 'flex']}
                  px={8}
                  isActive={pathname === '/' ? true : false}
                  onClick={() => {
                    push('/');
                    currentMeetingState && dispatch(setMeetingWindowed());
                  }}
                  icon={<HomeIcon width="35" height="35" />}
                  variant="homeHeaderButtons"
                />
              </Tooltip>
              <Tooltip label="Network" bg="black" p={2}>
                <IconButton
                  display={['none', 'none', 'flex']}
                  bg="transparent"
                  px={8}
                  isActive={pathname === '/profile/network' ? true : false}
                  onClick={() => {
                    push('/profile/network');
                    currentMeetingState && dispatch(setMeetingWindowed());
                  }}
                  icon={<TeamIcon width="35" height="35" />}
                  variant="homeHeaderButtons"
                />
              </Tooltip>
              <Tooltip label="Jobs" bg="black" p={2}>
                <IconButton
                  display={['none', 'none', 'flex']}
                  bg="transparent"
                  px={8}
                  isActive={pathname === '/jobs' ? true : false}
                  onClick={() => {
                    push('/jobs');
                    currentMeetingState && dispatch(setMeetingWindowed());
                  }}
                  icon={<JobsIcon width="35" height="35" />}
                  variant="homeHeaderButtons"
                />
              </Tooltip>
              <NotificationDropdown />
            </HStack>
          )}

          <HStack pr={[0, '50px']} justifyContent="flex-end">
            <HStack spacing={12} alignItems="center">
              <HStack spacing={[3, 3, 4]}>
                {isMobile || isTablet ? (
                  <IconButton
                    variant="transparent"
                    px={0}
                    minW="32px"
                    borderRadius="full"
                    w="32px"
                    h="32px"
                    BoxShadow="0px 0px 10px 0px #00000029;0px 4px 5px 0px #0000000F inset;0px -5px 3px 0px #0000000F inset;"
                    bg="#DEDEDE"
                    icon={<SearchIcon width="18" height="18" />}
                    onClick={() => props.onSearchOpen()}
                  />
                ) : (
                  <></>
                )}
                {isMobile || isTablet ? <IconButton
                    variant="transparent"
                    px={0}
                    minW="32px"
                    borderRadius="full"
                    w="32px"
                    h="32px"
                    BoxShadow="0px 0px 10px 0px #00000029;0px 4px 5px 0px #0000000F inset;0px -5px 3px 0px #0000000F inset;"
                    bg="#DEDEDE"
                    icon={<AddIcon color="#5B5B5B" width="18" height="18" />}
                    onClick={() => dispatch(openCreatePostModal(true))}
                  /> : <></>}
                {!isMobile && !isTablet ? <StatusDropdown /> : <></>}
                {!isMobile ? (
                  <IconButton
                    bg="transparent"
                    position="relative"
                    px={[1, 2, 8]}
                    size={isMobile ? 'sm' : 'md'}
                    variant="homeHeaderButtons"
                    onClick={() => {
                      push('/teams');
                      currentMeetingState && dispatch(setMeetingWindowed());
                    }}
                  >
                    <>
                      {teamChatNotications > 0 ? (
                        <Badge
                          position="absolute"
                          bg="brandRed.500"
                          color="white"
                          borderRadius="100%"
                          top="0"
                          minW="25px"
                          border="2px solid #ffffff"
                          p="3px 5px"
                          left="50%"
                        >
                          {teamChatNotications}
                        </Badge>
                      ) : null}

                      <Tooltip label="Teams" bg="black" p={2}>
                        <Box as="span">
                          <TeamNewIcon
                            width={isMobile ? '18' : '35'}
                            height={isMobile ? '18' : '35'}
                          />
                        </Box>
                      </Tooltip>
                    </>
                  </IconButton>
                ) : (
                  <IconButton
                    position="relative"
                    minW="32px"
                    borderRadius="full"
                    w="32px"
                    h="32px"
                    BoxShadow="0px 0px 10px 0px #00000029;0px 4px 5px 0px #0000000F inset;0px -5px 3px 0px #0000000F inset;"
                    bg="#DEDEDE"
                    onClick={() => {
                      push('/teams');
                      currentMeetingState && dispatch(setMeetingWindowed());
                    }}
                  >
                    <>
                      {teamChatNotications > 0 ? (
                        <Badge
                          position="absolute"
                          bg="brandRed.500"
                          color="white"
                          borderRadius="100%"
                          top="0"
                          minW="25px"
                          border="2px solid #ffffff"
                          p="3px 5px"
                          left="50%"
                        >
                          {teamChatNotications}
                        </Badge>
                      ) : null}

                      <Tooltip label="Teams" bg="black" p={2}>
                        <Box as="span">
                          <TeamNewIcon
                            width={isMobile ? '18' : '35'}
                            height={isMobile ? '18' : '35'}
                             color="#5B5B5B"
                          />

                        </Box>
                      </Tooltip>
                    </>
                  </IconButton>
                )}

                <ConversationList />
                {/* <MeetingDropdown /> */}
                {isMobile || isTablet ? (
                  <MyBridgeAvatar
                    name={userFullName}
                    src={userProfilePic}
                    size="sm"
                  />
                ) : (
                  <ProfileMenu>
                    <Button
                      borderRadius="100%"
                      padding={0}
                      variant="ghost"
                      style={{ boxShadow: 'rgb(0 0 0 / 43%) 0px 2px 15px 0px' }}
                    >
                      {/* <Tooltip label="Accouont" bg="black" p={2}> */}
                        <Box as="span">
                        <MyBridgeAvatar
                          name={userFullName}
                          src={userProfilePic}
                          size="lg"
                          style={{ width: '50px', height: '50px' }}
                        />
                        </Box>
                      {/* </Tooltip> */}
                    </Button>
                  </ProfileMenu>
                )}
              </HStack>
            </HStack>
          </HStack>
        </>
      ) : (
        <>
          {!isMobile && !isTablet ? (
            <HStack
              display={['none', 'none', 'flex']}
              spacing={4}
              flex={1}
              justifyContent="center"
            >
              <Tooltip label="Home" bg="black" p={2}>
                <IconButton
                  display={['none', 'none', 'flex']}
                  px={8}
                  isActive={pathname === '/' ? true : false}
                  onClick={() => {
                    push('/');
                  }}
                  icon={<HomeIcon width="24" height="24" />}
                  variant="homeHeaderButtons"
                />
              </Tooltip>
            </HStack>
          ) : (
            <></>
          )}
          {!isMobile && !isTablet ? (
            <HStack
              display={['none', 'none', 'flex']}
              spacing={4}
              justifyContent="flex-end"
            >
              <Button
                variant="secondary"
                onClick={handleSignInOpen}
                minW="32"
                fontWeight="semibold"
                color="#5E5E5E"
                borderRadius="10px"
              >
                Log in
              </Button>

              <Button
                onClick={handleSignUpOpen}
                variant="primary"
                minW="32"
                fontWeight="semibold"
                borderRadius="10px"
              >
                Sign up
              </Button>
            </HStack>
          ) : (
            <></>
          )}
        </>
      )}
      {openAuthModal === 'sign-in' ? (
        <SigninModal {...openAuthModalDisc} onClose={onClose} />
      ) : (
        ''
      )}
      {openAuthModal === 'sign-up' ? (
        <SignupModal {...openAuthModalDisc} onClose={onClose} />
      ) : (
        ''
      )}
      {openAuthModal === 'send-verify-link' ? (
        <SendVerifyLinkModal {...openAuthModalDisc} onClose={onClose} />
      ) : (
        ''
      )}
      {openAuthModal === 'activate-account' ? (
        <ActivateAccountModal {...openAuthModalDisc} onClose={onClose} />
      ) : (
        ''
      )}
      {openAuthModal === 'request-reset-password' ? (
        <RequestResetPasswordModal {...openAuthModalDisc} onClose={onClose} />
      ) : (
        ''
      )}
      {openAuthModal === 'confirm-reset-password' ? (
        <ConfirmResetPasswordModal {...openAuthModalDisc} onClose={onClose} />
      ) : (
        ''
      )}
      {openAuthModal === 'change-email' ? (
        <ChangeEmailModal {...openAuthModalDisc} onClose={onClose} />
      ) : (
        ''
      )}
    </>
  );
};
