/* eslint-disable @nx/enforce-module-boundaries */
import { useDisclosure, useOutsideClick } from '@mybridge/ui/hooks';
import { Input, InputGroup, InputLeftElement } from '@mybridge/ui/input';
import { List, ListItem } from '@mybridge/ui/list';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@mybridge/ui/popover';
import { useEffect, useRef, useState } from 'react';
import { useCountries } from 'v4/lib/hooks/use-countries';

export const CountryDropdown = ({
  value,
  defaultValue,
  onChange,
  leftIcon,
  ...props
}) => {
  const inputRef = useRef();
  const containerRef = useRef();
  const { countries } = useCountries({});
  const disc = useDisclosure();
  const [selected, setSelected] = useState(defaultValue);
  const [search, setSearch] = useState(defaultValue?.name);

  useEffect(() => {
    if (JSON.stringify(selected) !== JSON.stringify(value)) {
      const sel = countries?.find(
        (c) =>
          c?.name === value?.name ||
          c?.name === value?.country ||
          c?.code === value?.code
      );
      setSearch(value?.country ?? sel?.name);
      setSelected({ ...(value ?? {}), ...(sel ?? {}) });
    }
  }, [value, countries]);

  const handleSelect = (item) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSelected(item);
    onChange?.(item);
    setSearch(item?.name);
    disc?.onClose?.();
  };
  return (
    <Popover
      {...disc}
      matchWidth={true}
      initialFocusRef={inputRef}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <InputGroup>
          {leftIcon ? <InputLeftElement>{leftIcon}</InputLeftElement> : ''}
          <Input
            pl={leftIcon ? 10 : 4}
            ref={inputRef}
            value={search}
            onKeyUp={(e) =>
              e?.keyCode === 27 ? disc?.onClose?.() : disc?.onOpen?.()
            }
            onChange={(e) => {
              setSearch(e.target.value);
              disc?.onOpen?.();
            }}
            onBlur={(e) => {
              disc?.onClose?.();
            }}
            placeholder="Type to search..."
            {...props}
          />
        </InputGroup>
      </PopoverTrigger>
      {countries?.length && search?.length > 0 ? (
        <PopoverContent
          ref={containerRef}
          p={0}
          w="100%"
          maxH="200px"
          overflowY="auto"
        >
          <PopoverBody p={0}>
            <List>
              {countries
                ?.filter?.((c) =>
                  c?.name?.toLowerCase?.()?.includes(search?.toLowerCase?.())
                )
                ?.map?.((c, cind) => (
                  <ListItem
                    key={cind}
                    _hover={{
                      bg: 'gray.100',
                    }}
                    px={4}
                    py={2}
                    cursor="pointer"
                    onClick={handleSelect(c)}
                  >
                    {c.name}
                  </ListItem>
                ))}
            </List>
          </PopoverBody>
        </PopoverContent>
      ) : (
        ''
      )}
    </Popover>
  );
};
