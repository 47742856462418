/* eslint-disable @nx/enforce-module-boundaries */
import { useDisclosure, useToast } from '@mybridge/ui/hooks';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  applyJob,
  getActiveJobs,
  getApplyedJobs,
  getJobsDetail,
  getPublicJobsDetail,
  getSavedJobs,
  getSearchJobs,
  getUserJobPreferences,
  removeJob,
  removeUserJobPreferencesResume,
  reportJob,
  saveJobs,
  setUserJobPreferences,
  setUserJobPreferencesJSON,
} from 'v4/store/actions/Job.Action';
import { getNextPageFromURL } from 'v4/utils/utils';
import _ from 'lodash';
export const JobsListingContext = createContext({});

export const useJobsListingContext = ({
  refetchSuggestedJobsListingEnabled = false,
  refetchSavedJobsListingEnabled = false,
  refetchAppliedJobsListingEnabled = false,
  jobPreferencesRefetchEnabled = false,
  refetchSearchJobsListingEnabled = false,
  slug,
}) => {
  const [appliedJob, setAppliedJob] = useState();
  const [jobToReport, setJobToReport] = useState();
  const [filters, setFilters] = useState();
  const applyDisc = useDisclosure();
  const reportDisc = useDisclosure();

  const { userProfileInfo } = useSelector((state) => state?.userProfile) ?? {};
  const { loggedIn } = useSelector((state) => state?.user) ?? {};
  const dispatch = useDispatch();
  const toast = useToast({
    position: 'top',
  });

  // job details
  const {
    data: jobDetails,
    isFetching: jobDetailsFetching,
    refetch: refetchJobDetails,
  } = useQuery({
    queryKey: ['job-details', slug],
    queryFn: async () => {
      const resp = slug?.length
        ? loggedIn
          ? (await dispatch(getJobsDetail(slug)))?.payload
          : (await dispatch(getPublicJobsDetail(slug)))?.payload
        : null;
      return resp;
    },
    refetchOnWindowFocus: false,
  });

  // applied job details
  const {
    data: appliedJobDetails,
    isFetching: appliedJobDetailsFetching,
    refetch: refetchAppliedJobDetails,
  } = useQuery({
    queryKey: ['applied-job-details', appliedJob?.id],
    queryFn: async () => {
      const resp = appliedJob?.id
        ? (await dispatch(getJobsDetail(appliedJob?.id)))?.payload
        : null;
      return resp;
    },
    refetchOnWindowFocus: false,
  });

  // search listings
  const {
    data: searchJobsListingPages = { pages: [] },
    isFetching: searchJobsListingIsFetching,
    isLoading: searchJobsListingIsLoading,
    refetch: refetchSearchJobsListing,
    fetchNextPage: fetchSearchJobsListingNextPage,
    hasNextPage: searchJobsListingHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['search-jobs-listing', filters],
    queryFn: async ({ queryKey, pageParam = 1 }) => {
      const resp =
        (await dispatch(getSearchJobs({ query: queryKey?.[1], pageParam })))
          ?.payload ?? {};
      return resp;
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.next ? getNextPageFromURL(lastPage?.next) : undefined;
    },
    refetchOnWindowFocus: false,
    // enabled: refetchSearchJobsListingEnabled,
  });

  // suggested listings
  const {
    data: suggestedJobsListingPages = { pages: [] },
    isFetching: suggestedJobsListingIsFetching,
    refetch: refetchSuggestedJobsListing,
    fetchNextPage: fetchSuggestedJobsListingNextPage,
    hasNextPage: suggestedJobsListingHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['suggested-jobs-listing'],
    queryFn: async ({ pageParam, queryKey }) => {
      return (await dispatch(getActiveJobs({ pageParam })))?.payload ?? {};
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.next ? getNextPageFromURL(lastPage?.next) : undefined;
    },
    enabled: refetchSuggestedJobsListingEnabled,
  });

  // my listings
  const {
    data: savedJobsListingPages = { pages: [] },
    isFetching: savedJobsListingIsFetching,
    refetch: refetchSavedJobsListing,
    fetchNextPage: fetchSavedJobsListingNextPage,
  } = useInfiniteQuery({
    queryKey: ['my-jobs-listing'],
    queryFn: async () => {
      return (await dispatch(getSavedJobs()))?.payload ?? {};
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.next ? getNextPageFromURL(lastPage?.next) : undefined;
    },
    enabled: refetchSavedJobsListingEnabled,
  });

  // applied listings
  const {
    data: appliedJobsListingPages = { pages: [] },
    isFetching: appliedJobsListingIsFetching,
    isLoading: appliedJobsListingIsLoading,
    refetch: refetchAppliedJobsListing,
    fetchNextPage: fetchAppliedJobsListingNextPage,
    hasNextPage: appliedJobsListingHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['applied-jobs-listing'],
    queryFn: async () => {
      return (await dispatch(getApplyedJobs()))?.payload ?? {};
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.next ? getNextPageFromURL(lastPage?.next) : undefined;
    },
    enabled: refetchAppliedJobsListingEnabled,
  });

  /**
   * My job preferences
   */
  const {
    data: jobPreferences,
    refetch: refetchJobPreferences,
    isFetching: jobPreferencesFetching,
    isLoading: jobPreferencesLoading,
  } = useQuery({
    queryKey: ['jobPreferences'],
    queryFn: async () => {
      // if (!loggedIn) {
      //   return;
      // } else {
        const resp = await dispatch(getUserJobPreferences());
        return resp?.payload;
      // }
    },
    refetchOnWindowFocus: false,
    enabled: jobPreferencesRefetchEnabled,
  });

  /**
   * Update job preferences
   */
  const {
    data: updateJobPreferencesResult,
    isLoading: updateJobPreferencesLoading,
    mutateAsync: updateJobPreferencesAsync,
  } = useMutation({
    mutationKey: ['job-pref-update'],
    mutationFn: async ({ payload, mode }) => {
      if (mode === 'form') {
        return (await dispatch(setUserJobPreferences(payload)))?.payload;
      } else {
        return (await dispatch(setUserJobPreferencesJSON(payload)))?.payload;
      }
    },
  });

  /**
   * save a job
   */
  const {
    data: saveJobResult,
    isLoading: saveJobIsLoading,
    mutateAsync: saveJobAsync,
  } = useMutation({
    mutationKey: ['bookmark-job'],
    mutationFn: async (payload) => {
      return (await dispatch(saveJobs(payload)))?.payload;
    },
  });

  /**
   * report a job
   */
  const {
    data: reportJobResult,
    isLoading: reportJobIsLoading,
    mutateAsync: reportJobAsync,
  } = useMutation({
    mutationKey: ['report-job'],
    mutationFn: async (payload) => {
      return (await dispatch(reportJob(payload)))?.payload;
    },
  });

  /**
   * apply job
   */
  const {
    data: applyJobResult,
    isLoading: applyJobIsLoading,
    mutateAsync: applyJobAsync,
  } = useMutation({
    mutationKey: ['apply-job'],
    mutationFn: async (payload) => {
      return (await dispatch(applyJob(payload)))?.payload;
    },
  });

  /**
   * remove saved/bookmarked job
   */
  const {
    data: removeSavedJobResult,
    isLoading: removeSavedJobIsLoading,
    mutateAsync: removeSavedJobAsync,
  } = useMutation({
    mutationKey: ['remove-bookmarked-job'],
    mutationFn: async (payload) => {
      return (await dispatch(removeJob(payload)))?.payload;
    },
  });

  /**
   * Remove resume
   */
  const {
    data: removeJobPreferencesResumeResult,
    isLoading: removeJobPreferencesResumeLoading,
    mutateAsync: removeJobPreferencesResumeAsync,
  } = useMutation({
    mutationKey: ['job-pref-update-remove-resume'],
    mutationFn: async (payload) => {
      return (await dispatch(removeUserJobPreferencesResume(payload)))?.payload;
    },
  });

  /** util methods */

  /**
   * update job preferences
   * @param {*} data
   */
  const updateJobPreferences = async (payload, mode = 'form') => {
    try {
      const resp = await updateJobPreferencesAsync({ payload, mode });
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchJobPreferences();
        toast({
          title: 'Job Preference updated successfully!',
          status: 'success',
          position: 'top',
          isClosable: true,
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: 'Error while updating profile preferences!',
        status: 'error',
      });
    }
  };

  /**
   * Save job
   * @param {*} data
   */
  const applyJob_ = async (payload) => {
    try {
      const resp = await applyJobAsync(payload);
      if (resp?.state_code >= 400) {
        toast({ title: 'Unable to apply for the job!', status: 'error' });
      } else {
        refetchAppliedJobsListing();
        refetchSavedJobsListing();
        refetchSuggestedJobsListing();
        toast({
          title: 'Job application submitted successfully!',
          status: 'success',
        });
        applyDisc?.onClose?.();
      }
    } catch (e) {
      toast({
        title: 'Error while applying for job!',
        status: 'error',
      });
    }
  };

  /**
   * Save job
   * @param {*} data
   */
  const saveJob = async ({ job_id }) => {
    try {
      const resp = await saveJobAsync({
        user_id: userProfileInfo?.id,
        job_id,
      });
      if (resp?.state_code >= 400) {
        toast({ title: 'Unable to save job!', status: 'error' });
      } else {
        refetchSavedJobsListing();
        refetchSuggestedJobsListing();
        toast({ title: 'Job saved successfully!', status: 'success' });
      }
    } catch (e) {
      toast({
        title: 'Error while saving job!',
        status: 'error',
      });
    }
  };

  /**
   * Remove saved job
   * @param {*} data
   */
  const removeSavedJob = async ({ id }) => {
    try {
      const resp = await removeSavedJobAsync({
        id,
      });
      if (resp?.state_code >= 400) {
        toast({
          title: 'Unable to remove saved/bookmarked job!',
          status: 'error',
        });
      } else {
        refetchSavedJobsListing();
        refetchSuggestedJobsListing();
        toast({
          title: 'Job bookmark removed successfully!',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while removing saved/bookmarked job!',
        status: 'error',
      });
    }
  };

  /**
   * Report a job
   * @param {*} data
   */
  const reportJob_ = async (payload) => {
    try {
      const resp = await reportJobAsync(payload);
      if (resp?.state_code >= 400) {
        toast({
          title: 'Unable to report job!',
          status: 'error',
        });
      } else {
        toast({
          title: 'Job reported successfully!',
          status: 'success',
        });
        reportDisc?.onClose?.();
      }
    } catch (e) {
      toast({
        title: 'Error while reporting job!',
        status: 'error',
      });
    }
  };

  /**
   * remove resume
   * @param {*} data
   */
  const removeResume_ = async (resume) => {
    try {
      const resp = await removeJobPreferencesResumeAsync(resume);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchJobPreferences();
        toast({
          title: 'Resume removed successfully!',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while removing resume!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  useEffect(() => {
    refetchAppliedJobDetails?.();
  }, []);

  const suggestedJobsListing = useMemo(
    () =>
      [].concat.apply(
        [],
        suggestedJobsListingPages?.pages?.map((p) => p?.results ?? [])
      ),
    [suggestedJobsListingPages]
  );

  const appliedJobsListing = useMemo(
    () =>
      [].concat.apply(
        [],
        appliedJobsListingPages?.pages?.map((p) => p?.results ?? [])
      ),
    [appliedJobsListingPages]
  );

  const savedJobsListing = useMemo(
    () =>
      [].concat.apply(
        [],
        savedJobsListingPages?.pages?.map((p) => p?.results ?? [])
      ),
    [savedJobsListingPages]
  );

  const isSavedJob = (id) => {
    return savedJobsListing?.find?.((sj) => sj?.job_details?.id === id);
  };
  const isAppliedJob = (id) => {
    return appliedJobsListing?.find?.((sj) => sj?.job_details?.id === id);
  };
  // console.log('suggestedJobsListingPages', appliedJobsListingPages);
  return {
    suggestedJobsListing,
    suggestedJobsListingIsFetching,
    refetchSuggestedJobsListing,
    fetchSuggestedJobsListingNextPage,
    suggestedJobsListingHasNextPage,
    suggestedJobsListingCount: suggestedJobsListingPages?.pages?.length * 10,
    // saved
    savedJobsListing,
    savedJobsListingIsFetching,
    refetchSavedJobsListing,
    fetchSavedJobsListingNextPage,

    // applied
    appliedJobsListing,
    appliedJobsListingIsFetching,
    appliedJobsListingIsLoading,
    refetchAppliedJobsListing,
    fetchAppliedJobsListingNextPage,
    appliedJobsListingHasNextPage,
    // prefs
    jobPreferences,
    refetchJobPreferences,
    jobPreferencesFetching,
    jobPreferencesLoading,
    updateJobPreferences,
    updateJobPreferencesLoading,

    removeJobPreferencesResumeResult,
    removeJobPreferencesResumeLoading,
    removeResume: removeResume_,
    // job details
    jobDetails,
    jobDetailsFetching,
    refetchJobDetails,
    // save job
    saveJobResult,
    saveJobIsLoading,
    saveJob,
    isSavedJob,
    // remove saved job
    removeSavedJobResult,
    removeSavedJobIsLoading,
    removeSavedJob,

    // apply
    applyDisc,
    appliedJob,
    setAppliedJob,
    appliedJobDetails,
    appliedJobDetailsFetching,
    refetchAppliedJobDetails,
    isAppliedJob,
    applyJobResult,
    applyJobIsLoading,
    applyJob: applyJob_,

    // report
    reportDisc,
    reportJob: reportJob_,
    reportJobIsLoading,
    reportJobResult,
    jobToReport,
    setJobToReport,

    //search
    searchJobsListingPages,
    searchJobsListingIsFetching,
    refetchSearchJobsListing,
    fetchSearchJobsListingNextPage,
    searchJobsListingHasNextPage,
    filters,
    setFilters,
    searchJobsListingIsLoading,
  };
};

export const JobsListingProvider = ({ children, props }) => {
  const globalCtx = useJobsListingContext({ ...props });
  return (
    <JobsListingContext.Provider value={globalCtx}>
      {children}
    </JobsListingContext.Provider>
  );
};
