// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCpGIzglzei6D9WoWbctKo014FBUnEzM7M',
  authDomain: 'mybrige-notifications.firebaseapp.com',
  projectId: 'mybrige-notifications',
  storageBucket: 'mybrige-notifications.appspot.com',
  messagingSenderId: '455422196792',
  appId: '1:455422196792:web:b50e476d484a85008f3acd',
  measurementId: 'G-VGLPJWMG58',
};

// Initialize Firebase
export let firebaseApp = null;
export const getFirebaseApp = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig);
  }
  return firebaseApp;
};
export default firebaseApp;
