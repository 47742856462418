import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';
import axiosInstance from '../common/axios';

export const triggerFetchPosts = createAction('TriggerFetchPosts');

export const fetchPosts = createAsyncThunk('fetchPosts', async ({ pageParam = 1, location }) => {
  const obj = {
    url: APIEndPoints.Post(pageParam, 5, location),
  };
  return await API(obj);
});

export const fetchCompanyPosts = createAsyncThunk(
  'fetchCompanyPosts',
  async (body) => {
    const obj = {
      url: APIEndPoints.CompanyPostPublic(body.page, body.size, body.companyId),
    };
    return await API(obj);
  }
);

export const fetchUpdatedPosts = createAsyncThunk(
  'fetchUpdatedPosts',
  async (body) => {
    const obj = {
      url: APIEndPoints.Post(),
    };
    return await API(obj);
  }
);

export const postDislike = createAsyncThunk('postDislike', async (body) => {
  const obj = {
    url: APIEndPoints.PostDislike(body.id),
    method: 'DELETE',
    body: JSON.stringify(body.requestBody),
  };

  return await API(obj);
});

export const postLike = createAsyncThunk('postLike', async (body) => {
  const obj = {
    url: APIEndPoints.PostLike(body.id),
    method: 'PUT',
    body: JSON.stringify(body.requestBody),
  };

  return await API(obj);
});

export const postRepost = createAsyncThunk('postRepost', async (body) => {
  const obj = {
    url: APIEndPoints.PostRepost(body.id),
    method: 'POST',
  };

  return await API(obj);
});

export const quotePost = createAsyncThunk('quotePost', async (body) => {
  const obj = {
    url: APIEndPoints.PostRepost(body.id),
    method: 'POST',
    body: JSON.stringify(body),
  };

  return await API(obj);
});

export const pinPost = createAsyncThunk('pinPost', async (body) => {
  const obj = {
    url: APIEndPoints.PinPost(body.id),
    method: 'POST',
  };

  return await API(obj);
});
export const unpinPost = createAsyncThunk('unpinPost', async (body) => {
  const obj = {
    url: APIEndPoints.PinPost(body.id),
    method: 'DELETE',
  };

  return await API(obj);
});

export const postDelete = createAsyncThunk('postDelete', async (body) => {
  const obj = {
    url: APIEndPoints.PostDelete(body.id),
    method: 'DELETE',
  };
  const response = await API(obj);
  return { response, id: body?.id };
});

export const newPost = createAsyncThunk('newPost', async (body) => {
  const obj = {
    url: APIEndPoints.NewPost,
    method: 'POST',
    body: body,
    isFormData: true,
  };

  return await API(obj);
});

export const updatePost = createAsyncThunk('updatePost', async (body) => {
  const obj = {
    url: APIEndPoints.UpdatePost(body.id),
    method: 'PUT',
    body: body.formData,
    isFormData: true,
  };

  return await API(obj);
});

export const ignorePost = createAsyncThunk('ignorePost', async (body) => {
  const obj = {
    url: APIEndPoints.IgnorePost(body.id),
    method: 'POST',
  };

  return await API(obj);
});

export const listMyEvents = createAsyncThunk('listMyEvents', async (body) => {
  const obj = {
    url: APIEndPoints.GetEvents(),
  };
  return await API(obj);
});

export const getMyEventDetails = createAsyncThunk(
  'getMyEventDetails',
  async (id) => {
    const obj = {
      url: APIEndPoints.EventDetails(id),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getEditEventDetails = createAsyncThunk(
  'getEditEventDetails',
  async (id) => {
    const obj = {
      url: APIEndPoints.EventDetails(id),
    };
    return await API(obj);
  }
);


export const donwloadAttendeeCsv = createAsyncThunk(
  'donwloadAttendeeCsv',
  // async (id) => {
  //   const obj = {
  //     url: `${APIEndPoints.Events}${id}/attendee/downlaod`,
  //   };
  //   return await API(obj);
  // }
  async (id) => {
    const token = localStorage.getItem('access');
    const headers = {
      // 'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.get(
      `${process.env.BASE_API_URL}${APIEndPoints.Events}${id}/attendee/downlaod`, { headers }
    );
    return response;
  }
);

export const inviteAttendees = createAsyncThunk(
  'inviteAttendees',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Events}${body.id}/invite`,
      method:'POST',
      body: JSON.stringify({invitees:body.invitees, is_cohost:body.is_cohost}),
    };
    return await API(obj);
  }
);

export const updateAttendeeEventStatus = createAsyncThunk(
  'updateAttendeeEventStatus',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Events}${body.id}/attendee/${body.attendeeId}/update`,
      method:'PUT',
      body: JSON.stringify({status:body.status}),
    };
    return await API(obj);
  }
);

export const updateAttendeeJoiningStatus = createAsyncThunk(
  'updateAttendeeJoiningStatus',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Events}${body.id}/attendee/update/joining/status`,
      method:'PUT',
      body: JSON.stringify({attendee_ids:body.invitees}),
    };
    return await API(obj);
  }
);

export const acceptEvent = createAsyncThunk('acceptEvent', async (id) => {
  const obj = {
    url: APIEndPoints.AcceptEvent(id),
    method: 'POST',
  };
  return await API(obj);
});

export const registerEvent = createAsyncThunk('registerEvent', async (id) => {
  const obj = {
    url: APIEndPoints.RegisterEvent(id),
    method: 'POST',
  };
  return await API(obj);
});

export const ignoreEvent = createAsyncThunk('ignoreEvent', async (id) => {
  const obj = {
    url: APIEndPoints.IgnoreEvent(id),
    method: 'POST',
  };
  return await API(obj);
});

export const listUpcomingEvents = createAsyncThunk(
  'listUpcomingEvents',
  async ({ pageParam = 1, size = 10 }) => {
    const obj = {
      url: `${APIEndPoints.GetUpcomingEvents}?page_size=${size}&page=${pageParam}`,
      method: 'GET',
    };
    const res = await API(obj);
    return res;
  }
);

export const listEventByMe = createAsyncThunk(
  'listEventByMe',
  async ({ pageParam = 1, size = 10 }) => {
    const obj = {
      url: `${APIEndPoints.GetMyEvents}?page_size=${size}&page=${pageParam}`,
      method: 'GET',
    };
    const res = await API(obj);
    return res;
  }
);
export const listPastEvents = createAsyncThunk('listPastEvents', async ({ pageParam = 1, size = 10 }) => {
  const obj = {
    url: `${APIEndPoints.GetPastEvents}?page_size=${size}&page=${pageParam}`,
    method: 'GET',
  };
  return await API(obj);
});


export const getMyArticlesFunc = createAsyncThunk('getMyArticlesFunc', async ({ pageParam = 1, size = 10, status = '', include_bookmarks = false }) => {
  const obj = {
    url: `${APIEndPoints.GetBlogs(pageParam, size, status, include_bookmarks)}`,
    method: 'GET',
  };
  return await API(obj);
});

export const blockReportPost = createAsyncThunk(
  'blockReportPost',
  async (body) => {
    const obj = {
      url: APIEndPoints.BlockReportPost,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);


export const clearEditEvent = createAction('clearEditEvent');
export const openCreatePostModal = createAction('openCreatePostModal');