/* eslint-disable @nx/enforce-module-boundaries */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptRequest,
  blockConnection,
  getAllConnectionIDs,
  getAllConnectionsData,
  getDegreeConnectionsByUserId,
  getDegreeConnectionsSelfUser,
  getFollowers,
  getFollowingPages,
  getInvitations,
  getMutualConnections,
  getSuggestions,
  rejectRequest,
  remindConnectionRequest,
  reportConnection,
  requestConnection,
  requestFollow,
  unblockConnection,
  unfollow,
  unfriendConnection,
  withdrawConnection,
} from 'v4/store/actions/connection.actions';
import {
  getProfileViewers,
  getPublicProfile,
} from 'v4/store/actions/user-profile.actions';

import { useDisclosure, useToast } from '@mybridge/ui/hooks';
import { getUserFullName } from 'v4/lib/commons';
import { NetworkBlockUserModal } from 'v4/snippets/profile/network/block-user-modal';
import { NetworkBlockAndReportUserModal } from 'v4/snippets/profile/network/report-user-modal';
import { flattenQueryResults, getNextPageFromURL } from 'v4/utils/utils';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import {
  createMatrixRoom,
  inviteUserMatrixRoom,
  acceptUserMatrixInvite,
} from 'v4/store/actions/chat.actions';
import _ from 'lodash';

export const MyNetworkProvider = ({ children, ...props }) => {
  const networkCtx = useMyNetworkContext({});
  const { blockUserDisc, reportUserDisc } = networkCtx ?? {};
  useEffect(() => {
    console.log(reportUserDisc);
  }, [reportUserDisc?.isOpen]);

  return (
    <>
      <MyNetworkContext.Provider value={networkCtx}>
        {children}
        {blockUserDisc?.isOpen ? (
          <NetworkBlockUserModal {...blockUserDisc} />
        ) : (
          ''
        )}
        {reportUserDisc?.isOpen ? (
          <NetworkBlockAndReportUserModal {...reportUserDisc} />
        ) : (
          ''
        )}
      </MyNetworkContext.Provider>
    </>
  );
};

export const MyNetworkContext = createContext({});

export const useMyNetworkContext = ({
  refetchMyCircleEnabled = false,
  refetchMyFollowersEnabled = false,
  refetchMyFollowingEnabled = false,
  refetchPendingInvitationsEnabled = false,
  refetchRequestsSentEnabled = false,
  refetchSuggestionsEnabled = false,
  refetchBlockedEnabled = false,
  refetchFollowingPagesEnabled = false,
  refetchMutualConnectionsEnabled = false,
  refetchOtherUser1stDegreeEnabled = false,
  refetchOtherUser2ndDegreeEnabled = false,
  refetchOtherUser3rdDegreeEnabled = false,
  refetchMy1stDegreeEnabled = false,
  refetchMy2ndDegreeEnabled = false,
  refetchMy3rdDegreeEnabled = false,
  refetchOtherUserEnabled = false,
  refetchPageViewersEnabled = false,
  otherUserSlug,
}) => {
  const {
    allConnectionIDs,
    allDegreeConnectionsArr,
    loading: connectionsLoading,
  } = useSelector((state) => state?.connection);
  const tid = useRef(-1);
  const [filters, setFilters] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [searchQueryType, setSearchQueryType] = useState();
  const [suggestionType, setSuggestionType] = useState();
  const [handshakeSelectedUser, setHandshakeSelectedUser] = useState();
  const removeHandshakeRequestDisc = useDisclosure();
  const blockUserDisc = useDisclosure();
  const reportUserDisc = useDisclosure();
  const handshakeMessageDisc = useDisclosure();
  const moreSuggestionModalDisc = useDisclosure();
  const [activeCircleTab, setActiveCircleTab] = useState(0);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { loggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [errorExistCompany,setErrorExistCompany]=useState(true);

  const { matrixClient, updateDirectRooms } = useContext(MatrixContext);

  const toast = useToast({
    position: 'top',
    isClosable: true,
  });

  useEffect(() => {
    if (userProfileInfo?.id) dispatch(getAllConnectionIDs?.());
    // const chatToken = localStorage.getItem('chatAccess');
  }, [userProfileInfo?.id]);

  useEffect(() => {
    if (searchQuery) {
      clearTimeout(tid.current);
      tid.current = setTimeout(() => {
        if (searchQueryType === 'myFirstDegree') {
          refetchMyFirstDegreeConnections?.();
        } else if (searchQueryType === 'mySecondDegree') {
          refetchMySecondDegreeConnections?.();
        } else if (searchQueryType === 'myThirdDegree') {
          refetchMyThirdDegreeConnections?.();
        } else if (searchQueryType === 'myFollowers') {
          refetchMyFollowers?.();
        } else if (searchQueryType === 'myFollowing') {
          refetchMyFollowing?.();
        } else if (searchQueryType === 'mutualConnections') {
          refetchMutualConnections?.();
        } else if (searchQueryType === 'otherUserSecondDegreeConnections') {
          refetchOtherUserSecondDegreeConnections?.();
        } else if (searchQueryType === 'otherUserThirdDegreeConnections') {
          refetchOtherUserThirdDegreeConnections?.();
        } else if (searchQueryType === 'followingPages') {
          refetchFollowingPages?.();
        }
      }, 10);
    }
  }, [searchQuery, searchQueryType]);

  /**
   * other user
   */
  const {
    data: otherUser,
    refetch: refetchOtherUser,
    isLoading: otherUserLoading,
    isFetching: otherUserFetching,
  } = useQuery({
    queryKey: ['other-user', otherUserSlug],
    queryFn: async ({ queryKey }) => {
      const resp = await dispatch(getPublicProfile(queryKey?.[1]));
      return resp?.payload;
    },
    refetchOnWindowFocus: false,
    enabled: refetchOtherUserEnabled,
  });

  /**
   * viewers listing
   */
  const {
    data: profileViewers,
    refetch: refetchProfileViewers,
    isLoading: profileViewersLoading,
    isFetching: profileViewersFetching,
    hasNextPage: profileViewersHasNextPage,
    fetchNextPage: profileViewersFetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['profile-viewers'],
    queryFn: async ({ pageParam, queryKey }) => {
      const resp = await dispatch(getProfileViewers({ page: pageParam }));
      return resp?.payload;
    },
    refetchOnWindowFocus: false,
    enabled: refetchPageViewersEnabled,
    getNextPageParam: ({ lastPage }) => {
      return getNextPageFromURL(lastPage);
    },
  });

  /**
   * My network
   */
  const { data: myCircle, refetch: refetchMyCircle } = useQuery({
    queryKey: ['myCircle'],
    queryFn: async () => {
      const resp = await dispatch(getAllConnectionsData({}));
      return resp?.payload?.results ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: refetchMyCircleEnabled,
  });

  /**
   * My followers
   */
  const {
    data: myFollowers,
    refetch: refetchMyFollowers,
    isLoading: myFollowersIsLoading,
    isFetching: myFollowersIsFetching,
    hasNextPage: MyFollowersHasNextPage,
    fetchNextPage: MyFollowersFetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['myFollowers', { id: userProfileInfo?.id, search: searchQuery }],
    queryFn: async ({ pageParam, queryKey }) => {
      const { search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getFollowers({
          type: 'follower',
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: refetchMyFollowersEnabled,
    getNextPageParam: ({ lastPage }) => {
      return getNextPageFromURL(lastPage);
    },
  });

  /**
   * My following
   */
  const {
    data: myFollowing,
    refetch: refetchMyFollowing,
    isLoading: myFollowingIsLoading,
    isFetching: myFollowingIsFetching,
    hasNextPage: myFollowingHasNextPage,
    fetchNextPage: myFollowingFetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['myFollowing', { id: userProfileInfo?.id, search: searchQuery }],
    queryFn: async ({ pageParam, queryKey }) => {
      const { search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getFollowers({
          type: 'following',
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: refetchMyFollowingEnabled,
    getNextPageParam: ({ lastPage }) => {
      return getNextPageFromURL(lastPage);
    },
  });

  /**
   * Pending invites
   */
  const {
    data: pendingInvitations,
    refetch: refetchPendingInvitations,
    isLoading: pendingInvitationsIsLoading,
    isFetching: pendingInvitationsIsFetching,
    hasNextPage: pendingInvitationsHasNextPage,
    fetchNextPage: pendingInvitationsFetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['pending-invitations', { search: searchQuery }],
    queryFn: async ({ pageParam, queryKey }) => {
      const { search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getInvitations({
          type: 'pending',
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: refetchPageViewersEnabled,
    getNextPageParam: ({ lastPage }) => {
      return getNextPageFromURL(lastPage);
    },
  });

  /**
   * All Suggestions
   */
  const {
    data: suggestions,
    refetch: refetchSuggestions,
    isFetching: suggestionsIsFetching,
    isLoading: suggestionsIsLoading,
  } = useQuery({
    queryKey: ['suggestions'],
    queryFn: async () => {
      const resp = await dispatch(
        getSuggestions(
          '[university,follow,degree,role,industry,location,experience,trending,celebrites]'
        )
      );
      return resp?.payload ?? {};
    },
    refetchOnWindowFocus: false,
    enabled: refetchSuggestionsEnabled,
    cacheTime: 300000,
  });

  /**
   * Blocked users
   */
  const {
    data: blocked,
    isFetching: blockedIsFetching,
    isLoading: blockedIsLoading,
    refetch: refetchBlocked,
    fetchNextPage: blockedFetchNextPage,
    hasNextPage: blockedHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['blocked', { search: searchQuery }],
    queryFn: async ({ pageParam, queryKey }) => {
      const { search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getInvitations({
          type: 'blocked',
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchBlockedEnabled,
  });

  /**
   * Pages followed by users
   */
  const {
    data: followingPages,
    isFetching: followingPagesIsFetching,
    isLoading: followingPagesIsLoading,
    refetch: refetchFollowingPages,
    fetchNextPage: followingPagesFetchNextPage,
    hasNextPage: followingPagesHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['following-pages', { search: searchQuery }],
    queryFn: async ({ pageParam, queryKey }) => {
      const { search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getFollowingPages({
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchFollowingPagesEnabled,
  });

  /**
   * Requests sent
   */
  const {
    data: requestsSent,
    refetch: refetchRequestsSent,
    isLoading: requestsSentIsLoading,
    isFetching: requestsSentIsFetching,
    hasNextPage: requestsSentHasNextPage,
    fetchNextPage: requestsSentFetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['requests-sent', { search: searchQuery }],
    queryFn: async ({ pageParam, queryKey }) => {
      const { search } = queryKey?.[1] ?? {};
      // if (!loggedIn) {
      //   return;
      // } else {
      const resp = await dispatch(
        getInvitations({
          type: 'sent',
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
      // }
    },
    refetchOnWindowFocus: false,
    enabled: refetchRequestsSentEnabled,
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
  });

  /**
   * Mutual Connections
   */
  const {
    data: mutualConnections,
    isFetching: mutualConnectionsIsFetching,
    isLoading: mutualConnectionsIsLoading,
    refetch: refetchMutualConnections,
    fetchNextPage: mutualConnectionsFetchNextPage,
    hasNextPage: mutualConnectionsHasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'mutual-connections',
      { id: otherUser?.id, search: searchQuery },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const { id, search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getMutualConnections({
          userId: id,
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchMutualConnectionsEnabled,
  });

  /**
   * 1st degree
   */
  const {
    data: myFirstDegreeConnections,
    isFetching: myFirstDegreeConnectionsIsFetching,
    isLoading: myFirstDegreeConnectionsIsLoading,
    refetch: refetchMyFirstDegreeConnections,
    fetchNextPage: MyFirstDegreeConnectionsFetchNextPage,
    hasNextPage: MyFirstDegreeConnectionsHasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'first-degree-connections',
      { id: userProfileInfo?.id, search: searchQuery },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      if (!loggedIn) {
        return;
      } else {
        const { id, search } = queryKey?.[1] ?? {};
        const resp = await dispatch(
          getDegreeConnectionsSelfUser({
            userId: id,
            degree: 1,
            query: search,
            pageParam,
          })
        );
        return resp?.payload ?? [];
      }
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
  });

  /**
   * 2nd degree
   */

  const {
    data: mySecondDegreeConnections,
    isFetching: mySecondDegreeConnectionsIsFetching,
    isLoading: mySecondDegreeConnectionsIsLoading,
    refetch: refetchMySecondDegreeConnections,
    fetchNextPage: MySecondDegreeConnectionsFetchNextPage,
    hasNextPage: MySecondDegreeConnectionsHasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'second-degree-connections',
      { id: userProfileInfo?.id, search: searchQuery },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const { id, search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getDegreeConnectionsSelfUser({
          userId: id,
          degree: 2,
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchMy2ndDegreeEnabled,
  });

  /**
   * 3rd degree
   */
  const {
    data: myThirdDegreeConnections,
    isFetching: myThirdDegreeConnectionsIsFetching,
    isLoading: myThirdDegreeConnectionsIsLoading,
    refetch: refetchMyThirdDegreeConnections,
    fetchNextPage: MyThirdDegreeConnectionsFetchNextPage,
    hasNextPage: MyThirdDegreeConnectionsHasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'third-degree-connections',
      { id: userProfileInfo?.id, search: searchQuery },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const { id, search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getDegreeConnectionsSelfUser({
          userId: id,
          degree: 3,
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchMy2ndDegreeEnabled,
  });

  /**
   * 1st degree others
   */
  const {
    data: otherUserFirstDegreeConnections,
    isFetching: otherUserFirstDegreeConnectionsIsFetching,
    isLoading: otherUserFirstDegreeConnectionsIsLoading,
    refetch: refetchOtherUserFirstDegreeConnections,
    fetchNextPage: otherUserFirstDegreeConnectionsFetchNextPage,
    hasNextPage: otherUserFirstDegreeConnectionsHasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'first-degree-connections',
      { id: otherUser?.id, search: searchQuery },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const { id, search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getDegreeConnectionsByUserId({
          userId: id,
          degree: 1,
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchOtherUser1stDegreeEnabled,
  });

  /**
   * 2nd degree
   */
  const {
    data: otherUserSecondDegreeConnections,
    isFetching: otherUserSecondDegreeConnectionsIsFetching,
    isLoading: otherUserSecondDegreeConnectionsIsLoading,
    refetch: refetchOtherUserSecondDegreeConnections,
    fetchNextPage: otherUserSecondDegreeConnectionsFetchNextPage,
    hasNextPage: otherUserSecondDegreeConnectionsHasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'second-degree-connections',
      { id: otherUser?.id, search: searchQuery },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const { id, search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getDegreeConnectionsByUserId({
          userId: id,
          degree: 2,
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchOtherUser2ndDegreeEnabled,
  });

  /**
   * 3rd degree
   */
  const {
    data: otherUserThirdDegreeConnections,
    isFetching: otherUserThirdDegreeConnectionsIsFetching,
    isLoading: otherUserThirdDegreeConnectionsIsLoading,
    refetch: refetchOtherUserThirdDegreeConnections,
    fetchNextPage: otherUserThirdDegreeConnectionsFetchNextPage,
    hasNextPage: otherUserThirdDegreeConnectionsHasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'third-degree-connections',
      { id: otherUser?.id, search: searchQuery },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const { id, search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getDegreeConnectionsByUserId({
          userId: id,
          degree: 3,
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchOtherUser3rdDegreeEnabled,
  });

  /**
   * accept invitations
   */
  const {
    data: acceptInvitationResult,
    isLoading: acceptInvitationLoading,
    mutateAsync: acceptInvitationAsync,
  } = useMutation({
    mutationKey: ['network-accept-invite'],
    mutationFn: async (payload) => {
      return (await dispatch(acceptRequest(payload)))?.payload;
    },
  });

  /**
   * reject invitations
   */
  const {
    data: rejectInvitationResult,
    isLoading: rejectInvitationLoading,
    mutateAsync: rejectInvitationAsync,
  } = useMutation({
    mutationKey: ['network-reject-invite'],
    mutationFn: async (payload) => {
      return (await dispatch(rejectRequest(payload)))?.payload;
    },
  });

  /**
   * Follow request
   */
  const {
    data: followResult,
    isLoading: followLoading,
    mutateAsync: followAsync,
  } = useMutation({
    mutationKey: ['follow-user'],
    mutationFn: async (payload) => {
      return (await dispatch(requestFollow(payload)))?.payload;
    },
  });

  /**
   * Unfollow request
   */
  const {
    data: unfollowResult,
    isLoading: unfollowLoading,
    mutateAsync: unfollowAsync,
  } = useMutation({
    mutationKey: ['unfollow-user'],
    mutationFn: async (payload) => {
      return (await dispatch(unfollow(payload)))?.payload;
    },
  });

  /**
   * Unblock connection
   */
  const {
    data: unblockUserResult,
    isLoading: unblockUserLoading,
    mutateAsync: unblockUserAsync,
  } = useMutation({
    mutationKey: ['unblock-user'],
    mutationFn: async (payload) => {
      return (await dispatch(unblockConnection(payload)))?.payload;
    },
  });

  /**
   * Send handshake/connection request
   */
  const {
    data: handshakeResult,
    isLoading: handshakeLoading,
    mutateAsync: handshakeAsync,
  } = useMutation({
    mutationKey: ['handshake-user'],
    mutationFn: async (payload) => {
      return (await dispatch(requestConnection(payload)))?.payload;
    },
  });

  /**
   * remove handshake request
   */
  const {
    data: unfriendResult,
    isLoading: unfriendLoading,
    mutateAsync: unfriendAsync,
  } = useMutation({
    mutationKey: ['unfriend-user'],
    mutationFn: async (payload) => {
      return (await dispatch(unfriendConnection(payload)))?.payload;
    },
  });

  /**
   * Remind connection re
   */
  const {
    data: remindHandshakeResult,
    isLoading: remindHandshakeLoading,
    mutateAsync: remindHandshakeAsync,
  } = useMutation({
    mutationKey: ['remind-handshake'],
    mutationFn: async (payload) => {
      return (await dispatch(remindConnectionRequest(payload)))?.payload;
    },
  });

  /**
   * Block user
   */
  const {
    data: blockUserResult,
    isLoading: blockUserLoading,
    mutateAsync: blockUserAsync,
  } = useMutation({
    mutationKey: ['block-user'],
    mutationFn: async (payload) => {
      return (await dispatch(blockConnection(payload)))?.payload;
    },
  });

  /**
   * Report user
   */
  const {
    data: reportUserResult,
    isLoading: reportUserLoading,
    mutateAsync: reportUserAsync,
  } = useMutation({
    mutationKey: ['report-user'],
    mutationFn: async (payload) => {
      return (await dispatch(reportConnection(payload)))?.payload;
    },
  });

  /**
   * accept invite
   * @param {*} data
   */
  const acceptInvitation = async (connectionRequest) => {
    try {
      const resp = await acceptInvitationAsync({
        connection_request_id: connectionRequest?.id,
      });
      // const chatToken =
      //   typeof window !== 'undefined' && localStorage.getItem('chatAccess');
      // if (connectionRequest?.chat_room_id && chatToken) {
      //   dispatch(
      //     acceptUserMatrixInvite({
      //       roomId: connectionRequest?.chat_room_id,
      //       token: chatToken,
      //     })
      //   ).then((res) => console.log('User accepted Invitation!', res));
      // }
      await matrixClient
        .joinRoom(connectionRequest?.chat_room_id)
        .then(async function () {
          await updateDirectRooms(
            connectionRequest?.chat_room_id,
            connectionRequest?.user_info?.matrix_user_id
          );
          console.log(
            `${connectionRequest?.user_info?.matrix_user_id} joined ${connectionRequest?.chat_room_id} room perfectly`
          );
        });

      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchPendingInvitations();
        dispatch(getAllConnectionIDs());
        toast({
          title: 'Handshake request accepted',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while accepting invite!',
        status: 'error',
      });
    }
  };

  /**
   * remove invite
   * @param {*} data
   */
  const rejectInvitation = async (connectionRequest) => {
    try {
      const resp = await rejectInvitationAsync({
        connection_request_id: connectionRequest?.id,
      });
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        dispatch(getAllConnectionIDs());
        refetchPendingInvitations();
        refetchRequestsSent();
        removeHandshakeRequestDisc?.onClose?.();
        toast({
          title: 'Handshake request ignored',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while rejecting invite!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * block user
   * @param {*} data
   */
  const blockUser = async (user) => {
    try {
      const resp = await blockUserAsync({
        block_user_id: user?.id,
      });
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        dispatch(getAllConnectionIDs());
        refetchMyCircle();
        blockUserDisc?.onClose?.();
        toast({
          title: 'User has been blocked!',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while blocking user!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * report user
   * @param {*} data
   */
  const reportUser = async ({ user, message,onReport=false }) => {
    try {
      const resp = await reportUserAsync({
        block_user_id: user?.id,
        report_message: message?.message,
      });
      if (resp?.state_code >= 400) {
        setErrorExistCompany(true)
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        setErrorExistCompany(false)
        dispatch(getAllConnectionIDs());
        refetchMyCircle();
        if(onReport) return
        reportUserDisc?.onClose?.();
        toast({
          title: 'User has been reported!',
          status: 'success',
        });
      }
    } catch (e) {
      setErrorExistCompany(false)
      toast({
        title: 'Error while reporting user!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * follow user
   * @param {*} data
   */
  const followUser = async (user) => {
    try {
      const resp = await followAsync({
        follow_to: user?.id,
      });
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        dispatch(getAllConnectionIDs());
        refetchSuggestions();
        toast({
          title: `Following!`,
          // title: `You started following ${getUserFullName(user)}!`,
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while following user!',
        description: e?.message,
        status: 'error',
      });
    }
  };
  /**
   * unfollow user
   * @param {*} data
   */
  const unfollowUser = async (user) => {
    try {
      const resp = await unfollowAsync({
        follow_to: user?.id,
      });
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        dispatch(getAllConnectionIDs());
        refetchSuggestions();
        toast({
          // title: `You have unfollowed ${getUserFullName(user)}!`,
          title: `Unfollowed!`,
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while unfollowing user!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * handshake user
   * @param {*} data
   */
  const handshakeUser = async (user, message) => {
    // console.log(user);
    try {
      const payload = {
        creation_content: {
          'm.federate': true,
        },
        name: `${user?.first_name},${userProfileInfo?.first_name}`,
        preset: 'trusted_private_chat',
        invite: [user?.matrix_user_id],
        is_direct: true,
        //   initial_state: [
        //     {
        //         type: "m.room.encryption",
        //         state_key: "",
        //         content: {
        //             algorithm: "m.megolm.v1.aes-sha2"
        //         }
        //     }
        // ]
      };
      const createRoom = await dispatch(createMatrixRoom(payload)).then(
        async (res) => {
          if (res) {
            await updateDirectRooms(
              res?.payload?.room_id,
              user?.matrix_user_id
            );
            return res?.payload?.room_id;
          }
        }
      );
      const resp = await handshakeAsync({
        to_user_id: user?.id,
        chat_room_id: createRoom ? createRoom : null,
        message: message ? message : '',
      });
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        dispatch(getAllConnectionIDs());
        refetchRequestsSent();
        refetchSuggestions();
        handshakeMessageDisc?.onClose();
        setHandshakeSelectedUser();
        toast({
          // title: `Successfully sent connection request to ${getUserFullName(
          //   user
          // )}!`,
          title: `Handshake request sent`,
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while sending connection request!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * unfriend user
   * @param {*} data
   */
  const unfriendUser = async (user) => {
    try {
      const resp = await unfriendAsync(user?.id);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        dispatch(getAllConnectionIDs());
        refetchRequestsSent();
        refetchSuggestions();
        toast({
          title: `Handshake request removed successfully!`,
          status: 'success',
          isClosable: true,
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: 'Error while removing handshake request!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * remind conn req
   * @param {*} data
   */
  const remindHandshake = async (user) => {
    try {
      const resp = await remindHandshakeAsync(user?.id);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        if (resp?.message === 'Cannot remind more than once in 1 day') {
          toast({
            title: 'Cannot remind more than once in 1 day',
            status: 'error',
          });
        } else {
          toast({
            title: `Handshake request reminder sent!`,
            status: 'success',
          });
        }
      }
    } catch (e) {
      toast({
        title: 'Error while removing setting handshake request reminder!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * unblock conn
   * @param {*} data
   */
  const unblockUser = async (user) => {
    try {
      const resp = await unblockUserAsync({
        block_user_id: user?.id,
      });
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchBlocked?.();
        toast({
          title: `User unblocked successfully!`,
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while unblocking user!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  // query for withdrawing handshake
  const { mutate: revokeHandshake, isLoading: revokeHandshakeLoading } =
    useMutation((body) => dispatch(withdrawConnection(body)), {
      onSuccess: (data) => {
        if (data.status_code >= 400) {
          console.error(data);
        } else {
          dispatch(getAllConnectionIDs());
          refetchRequestsSent();
          toast({
            title: 'Handshake request removed',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const revokeHandhsakeRequest = (request) => {
    refetchRequestsSent?.();
    if (isHandshaked) {
      const request_id = _.find(
        requestsSent,
        (request) => request?.user_info?.id === request?.user_info?.id
      );
      // console.log('request_id', request_id);
      revokeHandshake({
        connection_request_id: request_id?.id,
      });
    }
  };

  const handleHandshakeSendRevoke = (user) => {
    if (isHandshaked) {
      revokeHandshake({
        // connection_request_id: request_id?.id,
        requested_for_id: user?.id,
      });
      refetchRequestsSent?.();
    }
  };

  useEffect(() => {
    refetchRequestsSent?.();
  }, []);

  const {
    handshake_requests,
    handshake_received,
    circle,
    following,
    company_following_ids,
  } = allConnectionIDs ?? {};

  const isHandshaked = (user) =>
    handshake_requests?.includes?.(user?.id) ||
    circle?.includes?.(user?.id) ||
    handshake_received?.includes?.(user?.id);

  const isFriend = (user) => circle?.includes?.(user?.id);

  const isHandshakeRequested = (user) =>
    handshake_requests?.includes?.(user?.id);
  // handshake_received?.includes?.(user?.id);

  const isFollowed = (user) => following?.includes?.(user?.id);

  const isCompanyFollowed = (user) =>
    company_following_ids?.includes?.(user?.id);

  const profileViewers_ = flattenQueryResults(profileViewers);
  const myFirstDegreeConnections_ = flattenQueryResults(
    myFirstDegreeConnections
  );
  const mySecondDegreeConnections_ = flattenQueryResults(
    mySecondDegreeConnections
  );
  const myThirdDegreeConnections_ = flattenQueryResults(
    myThirdDegreeConnections
  );
  const pendingInvitations_ = flattenQueryResults(pendingInvitations);
  const myFollowers_ = flattenQueryResults(myFollowers);
  const myFollowing_ = flattenQueryResults(myFollowing);
  const mutualConnections_ = flattenQueryResults(mutualConnections);
  const otherUserFirstDegreeConnections_ = flattenQueryResults(
    otherUserFirstDegreeConnections
  );
  const otherUserSecondDegreeConnections_ = flattenQueryResults(
    otherUserSecondDegreeConnections
  );
  const otherUserThirdDegreeConnections_ = flattenQueryResults(
    otherUserThirdDegreeConnections
  );
  const followingPages_ = flattenQueryResults(followingPages);
  const blocked_ = flattenQueryResults(blocked);
  const requestsSent_ = flattenQueryResults(requestsSent);
  const {
    follow = [],
    university = [],
    degree = [],
    role = [],
    industry = [],
    location = [],
  } = suggestions ?? {};
  const allSuggestions = [
    ...university,
    ...role,
    ...industry,
    ...degree,
    ...location,
    ...follow,
  ];

  const tabs = useMemo(
    () => [
      {
        name: 'pending',
        // title: `Received ${
        //   pendingInvitations?.length ? `(${pendingInvitations?.length})` : ''
        // }`,
        title: `Received`,
        show: true,
      },
      {
        name: 'sent',
        // title: `Requests Sent ${
        //   requestsSent?.length ? `(${requestsSent?.length})` : ''
        // }`,
        title: `Sent`,
        show: true,
      },
      // {
      //   name: 'suggestions',
      //   title: 'Suggestions',
      //   show: true,
      // },
    ],
    [pendingInvitations, requestsSent]
  );

  useEffect(() => {
    const active_ = tabs?.find?.((t) => t.show)?.name;
    console.warn(active_);
    setActiveTab(active_);
  }, [pendingInvitations, requestsSent, tabs]);

  const [activeTab, setActiveTab] = useState(tabs?.[0]?.name);

  const sharedTabs = useMemo(
    () => [
      {
        name: 'mutual',
        // title: `Mutual ${
        //   mutualConnections?.length ? `(${mutualConnections?.length})` : ''
        // }`,
        title: `Mutual`,
        // show: mutualConnections?.length,
        show: true,
      },
      // {
      //   name: 'first',
      //   title: `1st Degree `,
      //   show: true,
      // },
      {
        name: 'second',
        title: `2nd Degree`,
        show: true,
      },
      {
        name: 'third',
        title: `3rd Degree`,
        show: true,
      },
    ],
    [
      otherUserFirstDegreeConnections,
      otherUserSecondDegreeConnections,
      otherUserThirdDegreeConnections,
      mutualConnections,
    ]
  );

  // useEffect(() => {
  //   const active_ = sharedTabs?.find?.((t) => t.show)?.name;
  //   setActiveSharedTab(active_);
  // }, [
  //   [
  //     otherUserFirstDegreeConnections,
  //     otherUserSecondDegreeConnections,
  //     otherUserThirdDegreeConnections,
  //     mutualConnections,
  //     sharedTabs,
  //   ],
  // ]);

  const [activeSharedTab, setActiveSharedTab] = useState(sharedTabs?.[0]?.name);

  return {
    userProfileInfo,
    allConnectionIDs,
    refetchMyCircle,
    myCircle: allDegreeConnectionsArr?.[0] ?? [],
    // allDegreeConnectionsArr,
    filters,
    setFilters,
    removeHandshakeRequestDisc,
    blockUserDisc,
    reportUserDisc,
    selectedUser,
    setSelectedUser,

    //
    blockUser,
    blockUserLoading,
    reportUser,
    reportUserLoading,
    unblockUser,
    unblockUserLoading,
    errorExistCompany,

    // followers
    myFollowers: myFollowers_,
    refetchMyFollowers,
    myFollowersIsFetching,
    myFollowersIsLoading,
    MyFollowersHasNextPage,
    MyFollowersFetchNextPage,
    myFollowersCount: myFollowers?.pages?.[0]?.count,
    myFollowersPagingCount: myFollowers?.pages?.length * 10,

    // following
    myFollowing: myFollowing_,
    refetchMyFollowing,
    myFollowingIsFetching,
    myFollowingIsLoading,
    followUser,
    followLoading,
    unfollowUser,
    unfollowLoading,
    myFollowingHasNextPage,
    myFollowingFetchNextPage,
    myFollowingCount: myFollowing?.pages?.[0]?.count,
    myFollowingPagingCount: myFollowing?.pages?.[0]?.length * 10,

    //pending invitations
    pendingInvitations: pendingInvitations_,
    refetchPendingInvitations,
    pendingInvitationsIsFetching,
    pendingInvitationsIsLoading,
    acceptInvitation,
    acceptInvitationLoading,
    rejectInvitation,
    rejectInvitationLoading,
    pendingInvitationsCount: pendingInvitations?.pages?.[0]?.count,
    pendingInvitationsPagingCount: pendingInvitations?.pages?.length * 10,

    // requests sent
    requestsSent: requestsSent_,
    refetchRequestsSent,
    requestsSentIsFetching,
    requestsSentIsLoading,
    handshakeUser,
    handshakeLoading,
    unfriendUser,
    unfriendLoading,
    remindHandshake,
    remindHandshakeLoading,
    isHandshaked,
    isHandshakeRequested,
    isFollowed,
    isFriend,

    //suggestions
    suggestions,
    allSuggestions,
    refetchSuggestions,
    suggestionsIsFetching,
    suggestionsIsLoading,

    //blocked
    blocked: blocked_,
    refetchBlocked,
    blockedIsFetching,
    blockedIsLoading,
    blockedFetchNextPage,
    blockedHasNextPage,
    blockedCount: blocked?.pages?.[0]?.count,
    blockedPagingCount: blocked?.pages?.length * 10,

    //other user
    otherUser,
    refetchOtherUser,
    otherUserLoading,
    otherUserFetching,

    //mutual connections with other user
    mutualConnections: mutualConnections_,
    refetchMutualConnections,
    mutualConnectionsIsFetching,
    mutualConnectionsIsLoading,
    mutualConnectionsFetchNextPage,
    mutualConnectionsHasNextPage,
    mutualConnectionsCount: mutualConnections?.pages?.[0]?.count,
    mutualConnectionsPagingCount: mutualConnections?.pages?.length * 10,

    //1st degree for other user
    otherUserFirstDegreeConnections: otherUserFirstDegreeConnections_,
    refetchOtherUserFirstDegreeConnections,
    otherUserFirstDegreeConnectionsIsFetching,
    otherUserFirstDegreeConnectionsIsLoading,
    otherUserFirstDegreeConnectionsFetchNextPage,
    otherUserFirstDegreeConnectionsHasNextPage,
    otherUserFirstDegreeConnectionsCount:
      otherUserFirstDegreeConnections?.pages?.[0]?.count,
    otherUserFirstDegreeConnectionsPagingCount:
      otherUserFirstDegreeConnections?.pages?.length * 10,

    //2nd degree for other user
    otherUserSecondDegreeConnections: otherUserSecondDegreeConnections_,
    refetchOtherUserSecondDegreeConnections,
    otherUserSecondDegreeConnectionsIsFetching,
    otherUserSecondDegreeConnectionsIsLoading,
    otherUserSecondDegreeConnectionsFetchNextPage,
    otherUserSecondDegreeConnectionsHasNextPage,
    otherUserSecondDegreeConnectionsCount:
      otherUserSecondDegreeConnections?.pages?.[0]?.count,
    otherUserSecondDegreeConnectionsPagingCount:
      otherUserSecondDegreeConnections?.pages?.length * 10,

    //3rd degree for other user
    otherUserThirdDegreeConnections: otherUserThirdDegreeConnections_,
    refetchOtherUserThirdDegreeConnections,
    otherUserThirdDegreeConnectionsIsFetching,
    otherUserThirdDegreeConnectionsIsLoading,
    otherUserThirdDegreeConnectionsFetchNextPage,
    otherUserThirdDegreeConnectionsHasNextPage,
    otherUserThirdDegreeConnectionsCount:
      otherUserThirdDegreeConnections?.pages?.[0]?.count,
    otherUserThirdDegreeConnectionsPagingCount:
      otherUserThirdDegreeConnections?.pages?.length * 10,

    //profile viewerrs
    profileViewers: profileViewers_,
    refetchProfileViewers,
    profileViewersLoading,
    profileViewersFetching,
    profileViewersHasNextPage,
    profileViewersFetchNextPage,
    profileViewersCount: profileViewers?.pages?.[0]?.count,
    profileViewersPagingCount: profileViewers?.pages?.length * 10,

    activeTab,
    setActiveTab,
    tabs,
    activeSharedTab,
    setActiveSharedTab,
    sharedTabs,
    activeCircleTab,
    setActiveCircleTab,

    myFirstDegreeConnections: myFirstDegreeConnections_,
    refetchMyFirstDegreeConnections,
    myFirstDegreeConnectionsIsFetching,
    myFirstDegreeConnectionsIsLoading,
    MyFirstDegreeConnectionsFetchNextPage,
    MyFirstDegreeConnectionsHasNextPage,
    myFirstDegreeConnectionsCount: myFirstDegreeConnections?.pages?.[0]?.count,
    myFirstDegreeConnectionsPagingCount:
      myFirstDegreeConnections?.pages?.length * 10,

    mySecondDegreeConnections: mySecondDegreeConnections_,
    refetchMySecondDegreeConnections,
    mySecondDegreeConnectionsIsFetching,
    mySecondDegreeConnectionsIsLoading,
    MySecondDegreeConnectionsFetchNextPage,
    MySecondDegreeConnectionsHasNextPage,
    mySecondDegreeConnectionsCount:
      mySecondDegreeConnections?.pages?.[0]?.count,
    mySecondDegreeConnectionsPagingCount:
      mySecondDegreeConnections?.pages?.length * 10,

    myThirdDegreeConnections: myThirdDegreeConnections_,
    refetchMyThirdDegreeConnections,
    myThirdDegreeConnectionsIsFetching,
    myThirdDegreeConnectionsIsLoading,
    MyThirdDegreeConnectionsFetchNextPage,
    MyThirdDegreeConnectionsHasNextPage,
    myThirdDegreeConnectionsCount: myThirdDegreeConnections?.pages?.[0]?.count,
    myThirdDegreeConnectionsPagingCount:
      myThirdDegreeConnections?.pages?.length * 10,

    isCompanyFollowed,

    followingPages: followingPages_,
    refetchFollowingPages,
    followingPagesIsFetching,
    followingPagesIsLoading,
    followingPagesFetchNextPage,
    followingPagesHasNextPage,
    followingPagesCount: followingPages?.pages?.[0]?.count,
    followingPagesPagingCount: followingPages?.pages?.length * 10,

    searchQuery,
    setSearchQuery,
    searchQueryType,
    setSearchQueryType,
    handshakeSelectedUser,
    setHandshakeSelectedUser,
    handshakeMessageDisc,
    moreSuggestionModalDisc,
    suggestionType,
    setSuggestionType,
    revokeHandhsakeRequest,
    handleHandshakeSendRevoke,
    revokeHandshakeLoading,
    connectionsLoading,
  };
};
