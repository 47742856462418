import { PopoverTrigger } from '@chakra-ui/react';
import {
  ArticleIcon,
  ArticleIconColored,
  CalendarNewIcon,
  ChevronRight,
  DiskNewIcon,
  JobsIcon,
  JobsIconColored,
  PageIconColored,
} from '@mybridge/icons';
import {
  MyCalendarIcon,
  MyDiskIcon,
  PagesIcon,
  SettingsIcon,
  TeamsIcon,
  TeamsIconColored,
} from '@mybridge/icons/user-menu';
import { Avatar } from '@mybridge/ui/avatar';
import { Button } from '@mybridge/ui/button';
import { Divider } from '@mybridge/ui/divider';
import { Heading } from '@mybridge/ui/heading';
import { Box, HStack, SimpleGrid, Stack, VStack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
} from '@mybridge/ui/popover';
import { Text } from '@mybridge/ui/text';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { logout } from 'packages/mybridgev4/store/actions/auth.actions';
import { useDisclosure } from '@mybridge/ui/hooks';
import { useContext, useEffect } from 'react';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { setMeetingWindowed } from 'v4/store/actions/meeting.actions';
import { useSession, signIn, signOut } from 'next-auth/react';
import { isMobile } from 'react-device-detect';
import StatusDropdown from 'v4/components/header/status-dropdown';
import { Card, Portal } from '@mybridge/ui';
import { SiteShortcutLinks } from 'v4/snippets/dashboard/site-shortcut-links';
import { useMemo } from 'react';
import { MyEventsIcon, MyEventsIconColored } from '@mybridge/icons/MyEvents';
import { useRouter } from 'next/router';

export const ProfileMenu = ({ children }) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { currentMeetingState } = useSelector((state) => state.meeting);
  const { experience, educations, position } = userProfileInfo;
  const dispatch = useDispatch();
  const { push } = useRouter();
  const router = useRouter();
  const menuDisc = useDisclosure();
  const { matrixClient } = useContext(MatrixContext);
  const { data: session } = useSession();

  const mainMenu = useMemo(() => {
    return [
      {
        title: 'MyCalendar',
        icon: <CalendarNewIcon width="20" height="20" />,
        link: '/profile/calendar',
      },
      {
        title: 'MyDisk',
        icon: <DiskNewIcon width="20" height="20" />,
        link: '/profile/disk',
      },
      {
        title: 'MyEvents',
        icon: <MyEventsIconColored width="20" height="20" />,
        link: '/myevents/',
      },
      {
        title: 'MyArticles',
        icon: <ArticleIconColored width="20" height="20" />,
        link: '/articles/',
      },
      {
        title: 'Pages',
        icon: <PageIconColored width="20" height="20" />,
        link: '/settings/pages',
      },
      {
        title: 'Teams',
        icon: <TeamsIconColored width="20" height="20" />,
        link: '/teams',
      },
      {
        title: 'Jobs',
        icon: <JobsIconColored width="20" height="20" />,
        link: '/jobs',
      },
      {
        title: 'Settings',
        icon: <SettingsIcon width="20" height="20" />,
        link: isMobile ? '/settings' : '/settings/account',
      },
    ];
  }, []);

  const handleLogout = async () => {
    if (matrixClient) {
      // await matrixClient.logout(true);
      await matrixClient.stopClient();
      await matrixClient.clearStores();
    }
    dispatch(logout());
    push('/', {});
  };
  useEffect(() => {
    if (currentMeetingState) {
      dispatch(setMeetingWindowed());
    }
  }, [menuDisc.isOpen]);

  const userFullName = getUserFullName(userProfileInfo);
  const userProfilePic = getUserProfilePic(userProfileInfo);

  return (
    <>
      {!isMobile ? (
        <Popover {...menuDisc} placement="bottom-end">
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent shadow="base" borderRadius="xl" w="350px">
            <PopoverArrow />
            <PopoverBody p={2}>
              <Stack spacing={3}>
                <Card variant="thinBorderedCard" p={3}>
                  <HStack alignItems="flex-start">
                    <Avatar
                      name={userFullName}
                      src={userProfilePic}
                      width="50px"
                      height="50px"
                    />
                    <Stack spacing="0">
                      <Heading
                        fontSize="18px"
                        fontWeight="500"
                        color="brandPrimary.500"
                        as={Link}
                        onClick={(e) => menuDisc?.onClose?.()}
                        href="/profile/me"
                      >
                        {userFullName}
                      </Heading>
                      {experience?.id ? (
                        <>
                          <Text fontSize="13px">{experience?.title}</Text>
                          <Text fontSize="13px">
                            {experience?.company_name}
                          </Text>
                        </>
                      ) : educations?.length ? (
                        <Text fontSize="13px">{educations?.[0]?.name}</Text>
                      ) : (
                        <Text fontSize="13px">{position}</Text> ?? ''
                      )}
                    </Stack>
                  </HStack>
                  <Divider my={2} />
                  {/* <Link
                    onClick={(e) => menuDisc?.onClose?.()}
                    href="/profile/me"
                  > */}
                  <Button
                    color="brandPrimary.500"
                    fontSize="sm"
                    w="100%"
                    onClick={(e) => {
                      menuDisc?.onClose?.();
                      router.push('/profile/me');
                    }}
                  >
                    View Profile
                  </Button>
                  {/* </Link> */}
                </Card>
                <Card variant="thinBorderedCard" p={3}>
                  <SiteShortcutLinks isDropDown />
                </Card>
                <Link href="/settings/account">
                  <Button
                    variant="transparent"
                    w="100%"
                    justifyContent="space-between"
                    rightIcon={<ChevronRight height="12px" />}
                    h="60px"
                    px={4}
                    my={-2}
                  >
                    <HStack>
                      <Box
                        w="35px"
                        h="35px"
                        borderRadius="full"
                        bg="#D9D9D9"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <SettingsIcon width="18" height="18" />
                      </Box>
                      <Text>Settings</Text>
                    </HStack>
                  </Button>
                </Link>
                <Button
                  onClick={handleLogout}
                  variant="secondary"
                  w="100%"
                  mb={1}
                >
                  Logout
                </Button>
                <HStack justifyContent="flex-start" gap={0}>
                  <Button
                    variant="transparent"
                    // leftIcon={<TermsIcon />}
                    color="#5B5B5B"
                    size="sm"
                    onClick={(e) => {
                      menuDisc?.onClose?.();
                      push('/terms-of-use');
                    }}
                    fontSize="12px"
                    h="16px"
                  >
                    Terms
                  </Button>
                  <Text fontSize="12px">·</Text>
                  <Button
                    variant="transparent"
                    // leftIcon={<PrivacyIcon />}
                    color="#5B5B5B"
                    size="sm"
                    onClick={(e) => {
                      menuDisc?.onClose?.();
                      push('/privacy-policy');
                    }}
                    fontSize="12px"
                    h="16px"
                  >
                    Privacy
                  </Button>
                  <Text fontSize="12px">·</Text>
                  <Button
                    variant="transparent"
                    // leftIcon={<BusinessIcon />}
                    color="#5B5B5B"
                    size="sm"
                    onClick={(e) => {
                      menuDisc?.onClose?.();
                      push('/business');
                    }}
                    fontSize="12px"
                    h="16px"
                  >
                    Business
                  </Button>
                  <Text fontSize="12px">·</Text>
                  <Button
                    variant="transparent"
                    // leftIcon={<HelpSupportIcon />}
                    color="#5B5B5B"
                    size="sm"
                    onClick={(e) => {
                      menuDisc?.onClose?.();
                      push('/support');
                    }}
                    fontSize="12px"
                    h="16px"
                  >
                    Support
                  </Button>
                </HStack>
                <HStack justifyContent="flex-start" gap={0} mt="-10px">
                  <Text fontSize="12px" color="#5B5B5B" ps={3}>
                    myBridge Corporation © 2024
                  </Text>
                </HStack>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <Popover {...menuDisc} placement="bottom-end">
          <PopoverTrigger>{children}</PopoverTrigger>
          <Portal>
            <PopoverContent width="100vw" height="100%">
              <PopoverBody>
                <Stack width="100%" p={3}>
                  <HStack>
                    <Avatar name={userFullName} src={userProfilePic} />
                    <Stack spacing="0">
                      <Heading size="xs">{userFullName}</Heading>
                      <Link
                        onClick={(e) => menuDisc?.onClose?.()}
                        href="/profile/me"
                      >
                        <Text color="brandPrimary.500" fontSize="sm">
                          View Profile
                        </Text>
                      </Link>
                    </Stack>
                  </HStack>
                  <Divider py={2} />
                  <StatusDropdown />

                  <Heading as="h5" fontSize="lg" mt={3}>
                    All shortcuts
                  </Heading>

                  <SimpleGrid columns={2} spacing={3}>
                    {mainMenu.map?.((mi, mind) => (
                      <Link
                        href={mi.link}
                        onClick={(e) => {
                          menuDisc?.onClose?.();
                        }}
                      >
                        <VStack
                          key={mind}
                          alignItems="flex-start"
                          borderRadius="8px"
                          border="1px solid #ddd"
                          p={3}
                        >
                          {mi.icon}
                          <Text fontSize="lg">{mi.title}</Text>
                        </VStack>
                      </Link>
                    ))}
                  </SimpleGrid>
                  <Divider py={2} />
                  <Button
                    onClick={handleLogout}
                    variant="secondary"
                    w="100%"
                    mb={2}
                  >
                    Logout
                  </Button>
                  <HStack justifyContent="center">
                    <Text
                      onClick={(e) => menuDisc?.onClose?.()}
                      as={Link}
                      href="/privacy-policy"
                      color="brandGray.700"
                    >
                      Privacy policy
                    </Text>
                    <Text color="brandGray.700">-</Text>
                    <Text
                      onClick={(e) => menuDisc?.onClose?.()}
                      as={Link}
                      href="/terms-of-use"
                      color="brandGray.700"
                    >
                      Term of use
                    </Text>
                    <Text color="brandGray.700">-</Text>
                    <Text
                      onClick={(e) => menuDisc?.onClose?.()}
                      as={Link}
                      href="/about-us"
                      color="brandGray.700"
                    >
                      About
                    </Text>
                  </HStack>
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      )}
    </>
  );
};
