import React, { useEffect, useState } from 'react';
import {
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  Select,
  SelectProps,
} from '@chakra-ui/react';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en';
import { parsePhoneNumber } from 'react-phone-number-input';
import _ from 'lodash';
// import { valueContainerCSS } from 'react-select/dist/declarations/src/components/containers';

export function PhoneNumberInput({
  children,
  value,
  defaultValue,
  onChange,
  inputProps,
  selectProps,
  defaultDialCode,
  onDailCodeChange,
  ...props
}) {
  const separate = (val) => {
    const [dialCode_, phone_] = ((val ?? '') + '').split('-') ?? [];
    return {
      dialCode: dialCode_?.length ? dialCode_ : undefined,
      phone: phone_,
    };
  };

  const join = (dialCode_, phone_) => {
    return [dialCode_ ?? '', phone_ ?? ''].join('-');
  };
  const [phone, setPhone] = useState(value ?? defaultValue);
  const [country, setCountry] = useState('US');
  const [dialCode, setDialCode] = useState(
    separate(defaultValue)?.dialCode ?? defaultDialCode
  );

  useEffect(() => {
    if(defaultDialCode && value){
      const phoneNumber = parsePhoneNumber(`${defaultDialCode}${value}`);
      // console.log('rec', dialCode, phone, sep);
      setCountry(phoneNumber?.country);
      setPhone(phoneNumber.nationalNumber);
    }
  }, [value, defaultDialCode]);

  const onPhoneChange = (e) => {
    const phone_ = e?.target?.value;
    setPhone(phone_);
    if (phone_ && phone_.length && phone) {
      const v = join(getCountryCallingCode(country) ?? '1', phone_);
      // console.log(v);
      onChange?.(phone_);
      onDailCodeChange?.(getCountryCallingCode(country))
    } else {
      onChange?.('');
      onDailCodeChange?.('')
    }
  };


  const CountrySelect = ({ value, onChange, labels, ...rest }) => (
    <Select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
      border="none"
      bg="transparent"
    >
      <option value="">Country</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </Select>
  );


  return (
    <InputGroup {...props}>
      <InputLeftElement minW="200px">
        <CountrySelect labels={en} value={country} onChange={setCountry} />
      </InputLeftElement>
      <Input
        type="number"
        pl={'220px'}
        {...(inputProps ?? {})}
        value={phone}
        onChange={onPhoneChange}
      >
        {children}
      </Input>
    </InputGroup>
  );
}
